<template>
  <page-header-wrapper>
    <a-card>
      <add-freight ref="freightDialog" @getList="getList"></add-freight>
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form :layout="layoutValue">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="模板名称">
                <a-input v-model="queryParams.name" placeholder="请输入模板名称" allow-clear @pressEnter="handleQuery"/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-row :gutter="10" class="mb8">
        <a-col :span="1.5">
          <a-button
            plain
            type="primary"
            icon="el-icon-user"
            @click="addFreight"
          >新建运费模板</a-button>
        </a-col>
      </a-row>
      <div class="table-operations">
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          :pagination="false"
          @refresh="getList" />
      </div>
      <!-- 运费模板列表 -->
      <a-table
        :loading="loading"
        :data-source="tableData"
        :columns="columns"
        :size="tableSize"
        rowKey="id"
        :pagination="false">
        <div slot="appoint" slot-scope="text"> {{ text ? '开启' : '关闭' }} </div>
        <div slot="type" slot-scope="text"> {{ text == 1 ? '按件数' : text == 2 ? '按重量' : '' }} </div>
        <div slot="action" slot-scope="text, record" >
          <a href="javascript:void(0)" @click="handleClick(record)" class="margin-right-sm">编辑</a>
          <a-popconfirm
            title="确定要删除该运费模板吗？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="delFreight(record)"
          >
            <a href="javascript:void(0)" style="color:red;">删除</a>
          </a-popconfirm>
        </div>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParams.pageNum"
        :total="total"
        :page-size="queryParams.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
      <!-- <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      /> -->
    </a-card>
  </page-header-wrapper>
</template>
<script>
import addFreight from './add_freight.vue'
import * as logistics from '@/api/logistics'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Goods',
  mixins: [tableMixin],
  components: {
    addFreight
  },
  data() {
    return {
      loading: false,
      columns: [{
        title: 'ID',
        dataIndex: 'id',
        width: 60,
        type: 'text'
      }, {
        title: '模板名称',
        dataIndex: 'name',
        width: 180,
        type: 'number'
      }, {
        title: '计费方式',
        dataIndex: 'type',
        width: 100,
        scopedSlots: { customRender: 'type' }
      }, {
        title: '指定包邮',
        dataIndex: 'appoint',
        width: 100,
        scopedSlots: { customRender: 'appoint' }
      }, {
        title: '排序',
        dataIndex: 'sort',
        width: 60,
        type: 'number'
      }, {
        title: '添加时间',
        dataIndex: 'createTime',
        width: 150,
        type: 'time'
      }, {
        title: '操作',
        dataIndex: 'action',
        width: 150,
        fixed: 'right',
        scopedSlots: { customRender: 'action' }
      }],
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      tableData: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    /**
     * 获取运费模板列表
     */
     getList() {
      this.loading = true
      logistics.shippingTemplatesList(this.queryParams).then(response => {
        if (response.success) {
          this.tableData = response.data.records
          this.total = response.data.total
          this.loading = false
        }
      })
    },
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams.name = ''
      this.handleQuery()
    },
    /**
     * 新建运费模板
     */
    addFreight() {
      this.$refs.freightDialog.clear()
      this.$refs.freightDialog.handleShow()
    },
    /**
     * 编辑运费模板
     */
    handleClick(info) {
      // $refs.freightDialog.onShow()
      this.$refs.freightDialog.getInfo({
        id: info.id
      })
    },
    delFreight(info) {
      logistics.shippingDetete({ id: info.id }).then(response => {
        if (response.success) {
          const delIndex = this.tableData.findIndex(item => { return info.id == item.id })
          this.tableData.splice(delIndex, 1)
          this.$message.success('删除成功')
        } else {
          this.$message.error('删除失败')
        }
      })
    },
    onShowSizeChange (current, pageSize) {
      this.queryParams.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParams.pageNum = current
      this.queryParams.pageSize = pageSize
      this.getList()
    }
  }
}
</script>
